// Source https://jasonwatmore.com/post/2018/07/06/vue-vuex-jwt-authentication-tutorial-example
import store from '../store'

export const userService = {
    login,
    logout,
    qrLogin
};

// Logs the user in remotely by providing username and password
function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'username': username, 'password': password })
    };

    return fetch(`${process.env.VUE_APP_REST_URL}token/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function qrLogin(code){

    const requestOptions = {
        method: 'GET',
    };

    return fetch(`${process.env.VUE_APP_REST_URL}qr-auth?code=${code}`, requestOptions)
        .then(handleResponse)
        .then(response => {
                return response;
        })
}
// Logs the user out locally and remotely.
function logout() {
    // remove user from local storage to log user out
    const tokens = store.getters['authentication/tokens']
    const body = {"revoke_token": true}
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokens.accessToken}`},
        body: JSON.stringify(body),
    };

    return fetch(`${process.env.VUE_APP_REST_URL}accounts/logout/`, requestOptions)
        .then(response => {
            localStorage.removeItem('token');
            return response;
        });    
}


// Parses JSON from the API-Responses. When the server returns 401 the logout() function is triggered. s
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}