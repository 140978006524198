<template>
   <b-container>
      <!-- <footer class="py-5">
        <b-row>
          <b-col>
            <h5>{{ $t('Footer.about') }}</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">{{ $t('Footer.the_project') }}</a></li>
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">{{ $t('Footer.your_privacy') }}</a></li>
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">{{ $t('Footer.imprint') }}</a></li>
            </ul>
          </b-col>
    
          <b-col>
             <h5>{{ $t('help') }}</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">{{ $t('Footer.documentation') }}</a></li>
            </ul>           
          </b-col>
    
          <b-col>
            <h5>{{ $t('Footer.code_and_publications') }}</h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">{{ $t('Footer.front_end') }}</a></li>
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">{{ $t('Footer.back_end') }}</a></li>
            </ul>
          </b-col>
    
        </b-row>

    
        <div class="d-flex justify-content-between py-4 my-4 border-top">
          <p>&copy; 2021 Michael Achmann, {{ $t('Footer.uni') }}</p>
          <ul class="list-unstyled d-flex">
            <li class="ms-3"><a class="link-dark" href="https://twitter.com/michael_achmann" target="_blank"><b-icon icon="twitter"></b-icon></a></li>
            <li class="ms-3"><a class="link-dark" href="https://go.ur.de/michael-achmann" target="_blank"><b-icon icon="house-fill"></b-icon></a></li>
          </ul> 
        </div>
      </footer> //-->
    </b-container>
</template>

<script>

export default {
  name: 'Footer',
  props: [
      'user'
  ], 
  methods: {
  },
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.link-dark a{
    color: black;
}
</style>
