<template>
  <div id="app" class="full_height">
    
    
    <Navbar :user="user"/>

    <div class="content full_height">
      <router-view v-on:toast="toast" :user="user" :settings="settings" />
    </div>

    <Footer />

  </div>
</template>

<script>

import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import { apiService } from './services';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      session: null,
      setting_obj: null,
      timer: null,
      no_refresh: 0,
    }
  },
  methods:{
    settings(key, fallback=null){
      let setting
      if(this.setting_obj){
        let selected = this.setting_obj.filter(setting => setting.key == key)
        if(selected.length==1){
          setting = selected[0].value
        } else {
          setting = fallback
        }
      }

      return setting

    },
    create_session(){
      if(this.session == null){
      this.no_refresh = 0

      apiService.get({url:'sessions/', method:"POST", json:true, body: {owner: this.user.url, end: moment().format('YYYY-MM-DD HH:mm:ss.SSSSS')}})
                .then(response => {
                  this.session = response
                  this.no_refresh = 0
                })
                .catch(error => {
                  if(this.no_refresh > 2)
                    this.toast('Error Refreshing Session',`Please reload the page, otherwise your working time may not be logged. ${error} `, 'danger')
                  else
                    this.no_refresh++
                })
      } else {
        this.refresh_session()
      }

    },
    refresh_session: _.debounce(function() {
      if(this.user){
        if(this.session != null){
          this.session.end = moment().format()
          this.no_refresh = 0
          apiService.get({url:this.session.url, method: "PATCH", json:true, body: this.session })
                .then(response => {
                  if(response)
                    this.no_refresh = 0
                })
                .catch(error => {
                  if(this.no_refresh > 2)
                    this.toast('Error Refreshing Session',`Please reload the page, otherwise your working time may not be logged. ${error} `, 'danger')
                  else
                    this.no_refresh++
                })
        } else {
          this.create_session()
        }
      }


      this.reset_timer()

    }, 30000, {maxWait: 40000}),
    destroy_session() {
      this.session = null
    },
    reset_timer() {
        if(this.timer)
          clearTimeout(this.timer);
        
        this.timer = setTimeout(this.destroy_session, 90000)
    },
    toast(title, body, variant=null) {
        this.$bvToast.toast(body, {
                title,
                autoHideDelay: 3000,
                appendToast: true,
                variant,
                })
    }
  },
  computed: {
    user(){
        return this.$store.getters['authentication/user'];
      }, 
  },
  mounted() {
    if(this.user){
      this.$store.dispatch('authentication/updateUser')
        .then(() => {
          this.create_session()
        })
    }

    // Adding Listeners for detecting active state of user.
    document.addEventListener('click', this.refresh_session)
    document.addEventListener('keydown', this.refresh_session)

    this.reset_timer()

    document.title = "DeskSurvey"

    // Request Settings from Backend
    apiService.get({url:'settings/', method:"GET"})
                .then(response => {
                  this.setting_obj = response.results
                })
                .catch(error => {
                  this.toast('Error loading Settings', `Settings could not be loaded: ${error} `, 'danger')
                })
                

  }
}
</script>


<style>
.content {
  margin-top: 20px; 
}
html,
body,
.full_height {
  height: 90vh;
}

</style>
