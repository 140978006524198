import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js'
import router from './router'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import VueIntro from 'vue-introjs';

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)


// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)

Vue.use(VueIntro, {
  waitTimeout: 400
});
import 'intro.js/introjs.css';


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
