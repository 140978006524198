import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  /* {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      hideForAuth: true,
    }
  }, */
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Dashboard.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/dash',
    name: 'Dash',
    component: () => import(/* webpackChunkName: "dash" */ '../views/Dashboard.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Login.vue'),
  },
  {
    path: '/image/upload',
    name: 'ImageUpload',
    component: () => import(/* webpackChunkName: "imageupload" */ '../views/Image.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/image/annotate/:id',
    name: 'ImageAnnotation',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Image.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/image/edit/:id',
    name: 'ImageEditor',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Image.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/image/annotate/:id',
    name: 'ImageAnnotation',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Image.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/image/meta/:id',
    name: 'ImageMeta',
    component: () => import(/* webpackChunkName: "imagemeta" */ '../views/Image.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/surveys/:id',
    name: 'SurveysSubmit',
    component: () => import(/* webpackChunkName: "survey" */ '../views/Survey.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/surveys',
    name: 'Surveys',
    component: () => import(/* webpackChunkName: "survey" */ '../views/Survey.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "survey" */ '../views/Profile.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/xplog',
    name: 'XPLog',
    component: () => import(/* webpackChunkName: "survey" */ '../views/XPLog.vue'),
    meta: {
      requiresLogin: true,
    }
  },
  {
    path: '/images',
    name: 'Images',
    component: () => import(/* webpackChunkName: "survey" */ '../views/Images.vue'),
    meta: {
      requiresLogin: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const user = store.getters['authentication/user']
  if(to.matched.some(record => record.meta.requiresLogin)){
    if(!user){
      next({name: 'Login', 
      query: {redirect: to.fullPath,}
    })
    } else {
      next()
    } 
  }
  else {
      next()
    }
  if (to.matched.some(record => record.meta.hideForAuth)) {
      if (user) {
          next({ name: 'Dash' });
      } else {
          next();
      }
  } else {
      next();
  }
  })

export default router
