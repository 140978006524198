// Source https://jasonwatmore.com/post/2018/07/06/vue-vuex-jwt-authentication-tutorial-example

// API-Service provides standard methods for promised HTTP-Requests. By default each request contains
// the Authorization Bearer, provided by the accessToken from the Store. If a request fails with
// 401 error, the handleResponse method dispatches the refresh signal to the store and thereafter 
// retries the request. 

import store from '../store'

export const apiService = {
    get,
    refresh,
};

const MAX_CYCLES = 1 // Number of cycles the request repeats after 401 before logging the user out.

/* TODO: Wenn hier ein Fehler zurück kommt sollte geprüft werden, ob der Token aktualisiert werden kann
 wenn das nicht funktioniert wäre es sinnvoll hier den Logout einzuleiten */

 // GET the url, adding Auth Bearer, returning JavaScript Object.
function get({url= "", method="GET", json=true, body={}, locale='de', cycle=0}={}) {
    const tokens = store.getters['authentication/tokens']
    let headers = {}

    headers['Accept-Language'] = locale

    if(tokens.accessToken){
        headers['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    if(json){
        headers['Content-Type'] = 'application/json'
        if(cycle == 0)
            body = JSON.stringify(body)
    }

    if(method==="GET"){
        body=null
    }

    const requestOptions = {
        method,
        headers,
        body
    };

    let full_url;

    // In some cases using the URL supplied by the API might be the cleaner option. However, this prevents using any URL.
    if(url.indexOf(process.env.VUE_APP_REST_URL) >= 0){
        full_url = url
    } else {
        full_url = `${process.env.VUE_APP_REST_URL}${url}`
    }

    return fetch(full_url, requestOptions)
        .then(response => {
            return handleResponse({response, method, url, body, cycle, f:get, headers})
        })
}

// Refreshes the access token using the refresh token. Do not call this method directly,
// it should be called by the authentication store!
function refresh() {
    let tokens = store.getters['authentication/tokens']
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'refresh': tokens.refreshToken })
    };

    return fetch(`${process.env.VUE_APP_REST_URL}token/refresh/`, requestOptions)
        .then(response => {
            return response.text().then(text => {
                if (!response.ok) {
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error)
                }
                const data = text && JSON.parse(text); 
                return data;
            })
        });
    }

/* Parses the JSON from the response text into a javascript object. Checks for 401 errors 
 * and tries to refresh the token. TOOD: Restructure
 */
function handleResponse({response="", method=null, url="", body={}, cycle=0, f=get}={}) {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;

                    if (response.status === 401) {
                        if(store.getters['authentication/refreshing'] == true){
                            return f({url, method, body, cycle, f})
                        } else {
                            store.dispatch('authentication/refresh')
                                .then(() => {
                                    if(cycle <= MAX_CYCLES){
                                        cycle = cycle + 1;
                                        return f({url, method, body, cycle, f});
                                    } else {
                                        console.log("Max tries exceeded");
                                        store.dispatch('authentication/logout')
                                        return Promise.reject(error);
                                    }
                                });
                            }                        
                    } 
                
                    return Promise.reject(error);
                }
        
                return data;
            });
        }