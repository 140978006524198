<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand href="/" to="/">DeskSurvey</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="/" to="/">{{$t('Navbar.home')}}</b-nav-item>
        <b-nav-item href="/images" v-if="user" to="/images">{{$t('Navbar.images')}}</b-nav-item>
        <b-nav-item href="/surveys" v-if="user" to="/surveys">{{$t('Navbar.surveys')}}</b-nav-item>
        <b-nav-item href="https://docs.desks.digitalhumanities.io/" target="_blank">{{$t('Navbar.help')}}</b-nav-item>
        <!-- <b-nav-item href="/about" to="/about">About</b-nav-item> //-->
      </b-navbar-nav>

    <b-navbar-nav class="ml-auto">

      <b-nav-item-dropdown v-if="user" right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              {{ user.username }}
            </template>
            <b-dropdown-item href="/profile">{{$t('Navbar.user_settings')}}</b-dropdown-item>
            <b-dropdown-item href="/xplog">{{$t('Navbar.xp_vp_log')}}</b-dropdown-item>
            <b-dropdown-item @click="logout">{{$t('Navbar.sign_out')}}</b-dropdown-item>
          </b-nav-item-dropdown>

    <b-nav-item v-else right href="/login">{{$t('Navbar.login')}}</b-nav-item>

            <b-nav-item-dropdown right>
                  <template #button-content>
                    <span v-if="$i18n.locale == 'de'">
                      {{$t('Navbar.german')}}
                    </span>
                    <span v-else>
                      {{$t('Navbar.english')}}
                    </span>
                  </template>
            <b-dropdown-item @click="setLang('de')">{{$t('Navbar.german')}}</b-dropdown-item>
            <!--<b-dropdown-item @click="setLang('en')">{{$t('Navbar.english')}}</b-dropdown-item> //-->
          </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>

export default {
  name: 'Navbar',
  props: [
    'user',
  ], 
  data() {
    return {
      langs: ['en', 'de']
    }
  },
  methods: {
    logout() {
        const { dispatch } = this.$store;
        dispatch('authentication/logout');
    },
    setLang(lang){
      this.$i18n.locale = lang
    }
  },
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
