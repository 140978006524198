import { apiService, userService } from '../services';
import router  from '../router/';
// The initialState accesses the tokens and user from the local storage. If those are not available, the variables 
// are initialized as null. Error is null by default. Local Storage variables are stored as JSON.
const initialState = {  accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null,
                        refreshToken: localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null,
                        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,   
                        error: null,
                        refreshing: false
                    }

          
export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit, dispatch}, { username, password }) {
            return userService.login(username, password)
                .then(
                    tokens => {
                        commit('updateStorage', {access: tokens.access, refresh: tokens.refresh});
                        dispatch('updateUser')
                    },
                    error => {
                        commit('loginFailure', error);
                    }
                );
        },
        logout({ commit }) {
            userService.logout();
            commit('logout');
            router.push({name:"Login"}); // After logging out redirect to login page.
        },
        refresh({commit, getters }){
            if(getters.tokens.refreshToken){
                commit('refreshing', {refreshing: true})
                return apiService.refresh()
                        .then(
                            tokens => {
                                commit('updateStorage', {access: tokens.access, refresh: getters.tokens.refreshToken});
                                commit('refreshing', {refreshing: false})
    
                            },
                            error => {
                                commit('loginFailure', error);
                                commit('refreshing', {refreshing: false})
    
                            }
                        )   
            }
        },
        loginQR({commit, dispatch}, {access, refresh}){
            commit('updateStorage', {access, refresh})
            dispatch('updateUser')
        },
        updateUser({ commit, getters }){
            if(getters.tokens.refreshToken){
                apiService.get({url: 'users/', method: 'GET'})
                    .then(
                        users => {
                            if(users.count == 1){
                                let user = users.results[0]
                                commit('updateUser', { user });
                            }
                        }
                    )
            }
        }
    },
    mutations: {
        updateStorage(state, {access, refresh}){
            state.accessToken = access;
            state.refreshToken = refresh;

            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
        },
        updateUser(state, {user}){
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        loginFailure(state, error){
            state.accessToken = null;
            state.refreshToken = null;
            state.user = null; 

            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');

            state.error = error;
        },
        logout(state){
            state.accessToken = null;
            state.refreshToken = null;
            state.user = null; 

            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
           
        },
        refreshing(state, {refreshing}){
            state.refreshing = refreshing;
        }
    },
    getters: {
        loggedIn(state) {
            return state.accessToken != null;
        },
        error(state){
            return state.error;
        },
        tokens(state){
            return {
                accessToken: state.accessToken,
                refreshToken: state.refreshToken,
            }
        },
        user(state){
            return state.user;
        },
        refreshing(state){
            return state.refreshing;
        }
    }
}