export const translationService = {
    getTranslated
}

// Takes the translations dictionary returned by django for translated models and returns the `name` variable for the current locale; falls back to the first item in the dictionary if current locale does not exist.
function getTranslated(translations, name){
    let locale = Object.keys(translations)[0]  
    if(this.$i18n.locale in translations)
      locale = this.$i18n.locale
    
    return translations[locale][name]
  }