import { apiService } from '.';
import store from '../store'

export const introService = {
    check,
    seen
}

// Checks whether the intro.js tuorial has already been played for the current version of the component.
function check(name, version){
    const user = store.getters['authentication/user']

    let intro = user.intro.filter(intro => intro.component === name);
    intro = intro.filter(intro => intro.version < version)

    if(intro.length > 0)
        return intro.seen 
    else
        return seen(name,version,true)
            .then(() => {
                return false
            })
    
  }

function seen(name, version, seen=true){
    const user = store.getters['authentication/user']
    const intro = {
        user: user.id,
        component: name,
        version,
        seen,
    }
    return apiService.get({url: 'intros/', method:"POST", body:intro})
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            console.log(error)
        })
}